import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Collapse, Stack, Typography, Link } from '@mui/material';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';

const SignUpSiiFieldsCollapsableComponent = (
  { currentStep, selectedTab, setTermsOpen, nationalIdentifier, password, onChangeInput },
) => {
  const { t } = useTranslation();
  return (
    <Collapse in={currentStep === 2 && selectedTab === 'signUp'} sx={{ width: '100%' }}>
      <Stack spacing={1.5}>
        <ValidationTextFieldInput
          validationtype={`${t('National identifier')}`.toLowerCase()}
          name="nationalIdentifier"
          type="text"
          label={`${t('National identifier')} empresa`}
          variant="outlined"
          fullWidth
          value={nationalIdentifier}
          onChange={onChangeInput}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: {
              borderRadius: '30px',
            },
          }}
        />
        <PasswordInput
          name="password"
          label={`Clave ${t('National Tax Service acronym')}`}
          variant="outlined"
          fullWidth
          value={password}
          onChange={onChangeInput}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: {
              borderRadius: '30px',
            },
          }}
        />
        <Typography variant="caption" align="center">
          Al registrarte, declaras haber leído y aceptado nuestros
          <Link onClick={() => setTermsOpen(true)} to="">
            <Typography variant="caption" color="primary">
              {' términos y condiciones'}
            </Typography>
          </Link>
        </Typography>
      </Stack>
    </Collapse>
  );
};

SignUpSiiFieldsCollapsableComponent.propTypes = {
  setTermsOpen: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  selectedTab: PropTypes.string.isRequired,
  nationalIdentifier: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func.isRequired,
};

export default SignUpSiiFieldsCollapsableComponent;
