import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse, Stack, InputAdornment } from '@mui/material';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { PasswordStrength } from '../../../components/utils';

const SignUpFieldsCollapsableComponent = (
  { currentStep, selectedTab, formInput, formChange, errorForm },
) => {
  const { t } = useTranslation();
  return (
    <Collapse
      in={currentStep === 1 && selectedTab === 'signUp'}
      sx={{
        width: '100%',
        marginTop: '5px',
      }}
    >
      <Stack spacing={1.5}>
        <ValidationTextFieldInput
          name="firstName"
          type="text"
          label="Nombre"
          variant="outlined"
          validationtype="text"
          fullWidth
          value={formInput.fullName}
          onChange={(event) => formChange('firstName', event)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: {
              borderRadius: '30px',
            },
          }}
        />
        <ValidationTextFieldInput
          name="lastName"
          type="text"
          label="Apellido"
          variant="outlined"
          validationtype="text"
          fullWidth
          value={formInput.fullName}
          onChange={(event) => formChange('lastName', event)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: {
              borderRadius: '30px',
            },
          }}
        />
        <ValidationTextFieldInput
          name="phone"
          type="text"
          label="Teléfono Celular"
          variant="outlined"
          validationtype="phone"
          fullWidth
          value={formInput.phone}
          onChange={(event) => formChange('phone', event)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: <InputAdornment position="start">+{t('Phone prefix')}</InputAdornment>,
            style: {
              borderRadius: '30px',
            },
          }}
        />
        <PasswordInput
          name="password"
          label="Contraseña"
          variant="outlined"
          fullWidth
          value={formInput.password}
          onChange={(event) => formChange('password', event)}
          error={errorForm?.password}
          helperText={errorForm.password}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: {
              borderRadius: '30px',
            },
          }}
        />
        <PasswordStrength password={formInput.password} />
      </Stack>
    </Collapse>
  );
};

SignUpFieldsCollapsableComponent.propTypes = {
  currentStep: PropTypes.number.isRequired,
  selectedTab: PropTypes.string.isRequired,
  formInput: PropTypes.shape({
    fullName: PropTypes.string,
    phone: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  formChange: PropTypes.func.isRequired,
  errorForm: PropTypes.shape({ password: PropTypes.string }).isRequired,
};

export default SignUpFieldsCollapsableComponent;
