import { WaveFullHeightBox } from '@fingo/lib/components/boxes';
import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { useGetUser } from '@fingo/lib/hooks';
import { Redirect } from 'react-router-dom';
import OTPInput from '../components/otpLogin/OTPInput';

const OTPLogin = () => {
  const user = useGetUser();
  if (user) {
    return <Redirect to="/app" />;
  }
  return (
    <WaveFullHeightBox pt={10}>
      <Typography variant="h1" color="primary" align="center" paragraph letterSpacing={4}>
        ¡Nos alegra verte!
      </Typography>
      <Grid container maxWidth={900} mx="auto">
        <Grid xs={12} md={7} item container>
          <Paper variant="elevation" elevation={3}>
            <Box width="100%" px={8} py={8}>
              <Typography color="primary" variant="h3" paragraph>
                ¡Bienvenido a Fingo!
              </Typography>
              <Typography mt={4}>
                Si ya ingresaste tus credenciales del Servicio de
                Impuestos Internos en Office Banking, puedes utilizar tu correo para
                ingresar a Fingo y ver el estado de tus operaciones.
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid xs={12} md={5} item container>
          <Paper variant="elevation" elevation={3} sx={{ width: '100%' }}>
            <Box width="100%" py={4}>
              <OTPInput />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </WaveFullHeightBox>
  );
};

export default OTPLogin;
