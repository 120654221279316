import React, { useState } from 'react';
import { Sidebar } from '@fingo/lib/components/layout';
import Check from '@mui/icons-material/Check';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import PaidOutlined from '@mui/icons-material/PaidOutlined';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { Redirect } from 'react-router-dom';
import { getSerializedSearch } from '@fingo/lib/helpers/url-filter-translator';
import { FakeSales } from './App/sales';
import StepperRegisterForm from '../components/userActions/StepperRegisterForm';

const QuickRegistration = () => {
  const company = useSelectedCompany();
  const [registerDialog, setRegisterDialog] = useState(false);
  if (company) {
    return (
      <Redirect
        push
        to={{
          pathname: '/app/sales/accountable',
          search: getSerializedSearch({ siiStatus: ['Rejected'] }),
        }}
      />
    );
  }
  return (
    <>
      <StepperRegisterForm registerDialog={registerDialog} setRegisterDialog={setRegisterDialog} />
      <Sidebar
        options={[
          {
            id: 'quick-sales',
            label: 'Cuentas por cobrar',
            path: '',
            icon: <DescriptionOutlined />,
            component: (
              <FakeSales registerDialog={registerDialog} setRegisterDialog={setRegisterDialog} />
            ),
            disabled: false,
            show: true,
          },
          {
            id: 'quick-anticipate',
            label: 'Anticipa tus facturas',
            path: ' ',
            icon: <PaidOutlined />,
            component: <></>,
            disabled: false,
            show: true,
          },
          {
            id: 'quick-anticipate-oc',
            label: 'Anticipa tus OC',
            path: ' ',
            icon: <Check />,
            component: <></>,
            disabled: false,
            show: true,
          },
        ]}
      />
    </>
  );
};

export default QuickRegistration;
