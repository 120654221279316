/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { VALID_TOKEN, RESET_PASSWORD } from '@fingo/lib/graphql';
import { BottomWaveLogin, PasswordRecoveryBanner } from '@fingo/lib/assets';
import { urlParser, getFormFieldError } from '@fingo/lib/helpers';
import { Typography, Grid, TextField, Button, Hidden, CircularProgress, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AlertOneDialog from '@fingo/lib/components/dialogs/AlertOneDialog';

const useStyles = makeStyles({
  container: {
    padding: '5% 15% 0 15%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: '2rem',
    fontWeight: 500,
  },
  input: {
    padding: '14px 14px',
  },
  bottomWave: {
    position: 'absolute',
    width: '100vw',
    bottom: 0,
    left: 0,
    zIndex: -1,
  },
  illustration: {
    zIndex: 1,
  },
  banner: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
});

const RecoveryPasswordForm = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = urlParser(location.search);
  const history = useHistory();
  const { token } = params;
  const [errorMessage, setErrorMessage] = useState('');
  const [operationMessage, setOperationMessage] = useState('');
  const [operationTitle, setOperationTitle] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [formPassword, setFormPassword] = useState(
    {
      password: { value: '', error: '' },
      confirmPassword: { value: '', error: '' },
    },
  );
  const {
    loading: loadingTokenValidation,
    error: validateTokenError,
  } = useQuery(VALID_TOKEN, {
    variables: {
      token,
    },
  });

  const [setPassword,
    {
      loading: loadingSetPassword,
      data: dataSetPassword,
    }] = useMutation(RESET_PASSWORD);

  const { password, confirmPassword } = formPassword;

  useEffect(() => {
    if (password.value !== confirmPassword.value) {
      setErrorMessage('Debes ingresar la misma contraseña en ambos campos');
    } else {
      setErrorMessage('');
    }
  }, [password, confirmPassword]);

  const resetPassword = async () => {
    try {
      const response = await setPassword({ variables: { token, password: password.value } });
      if (response.data) {
        setOperationMessage('Se cambió tu contraseña con éxito. Inicia sesión con tu nueva contraseña.');
        setOperationTitle('Operación realizada con éxito');
        setOpenAlert(true);
      }
    } catch (_error) {
      setOperationMessage('Hubo un error al intentar cambiar tu contraseña. Recarga la página, o solicita nuevamente la recuperación de tu contraseña.');
      setOperationTitle('Error en la operación');
      setOpenAlert(true);
    }
  };

  const isButtonReady = () => {
    if (password.value === ''
    || password.error !== ''
    || confirmPassword.value === ''
    || confirmPassword.error !== ''
    || password.value !== confirmPassword.value
    || loadingSetPassword) {
      return false;
    }
    return true;
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value, type } = event.target;
    const fieldError = getFormFieldError(value, type, name);
    setFormPassword((oldState) => ({
      ...oldState,
      [name]: { ...oldState[name], error: fieldError.message, value },
    }));
  };

  const onClose = () => {
    setOpenAlert(false);
    if (dataSetPassword) {
      history.push('/app');
    }
  };

  if (loadingTokenValidation) return <LinearProgress />;

  if (validateTokenError && validateTokenError.message === 'expired token') {
    return (
      <div className={classes.container}>
        <Typography variant="h4">Lo sentimos, este link ya no está disponible. Debes solicitar recuperar tu contraseña nuevamente.</Typography>
      </div>
    );
  } else if (validateTokenError) {
    return (
      <div className={classes.container}>
        <Typography variant="h1">Lo sentimos, este link no es válido. Intenta recuperar tu contraseña nuevamente.</Typography>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} lg={6}>
          <div className={classes.title}>
            <Typography variant="h2" color="primary">Reestablecer contraseña</Typography>
          </div>
          <Typography style={{ marginBottom: '1rem' }} gutterBottom variant="body1">Por favor introduce una nueva contraseña</Typography>
          <div className={classes.form}>
            <div>
              <Grid container spacing={6} direction="column">
                <Grid item xs={12} sm={8}>
                  <Typography className={classes.label}>Contraseña</Typography>
                  <TextField
                    inputProps={{ className: classes.input }}
                    fullWidth
                    value={password.value}
                    name="password"
                    variant="outlined"
                    type="password"
                    error={password.error !== ''}
                    helperText={password.error}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography className={classes.label}>Confirmar contraseña</Typography>
                  <TextField
                    inputProps={{ className: classes.input }}
                    fullWidth
                    value={confirmPassword.value}
                    name="confirmPassword"
                    variant="outlined"
                    type="password"
                    error={confirmPassword.error !== ''}
                    helperText={confirmPassword.error}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography color="error" align="center" component="div">{errorMessage}</Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!isButtonReady()}
                    endIcon={loadingSetPassword ? <CircularProgress size={16} /> : null}
                    onClick={resetPassword}
                  >
                    Reestablecer contraseña
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Hidden lgDown>
          <Grid item lg={6}>
            <div className={classes.banner}>
              <img src={PasswordRecoveryBanner} alt="banner illustration" className={classes.illustration} />
            </div>
          </Grid>
        </Hidden>
      </Grid>
      <img src={BottomWaveLogin} className={classes.bottomWave} alt="Bottom wave decoration" />
      <AlertOneDialog
        open={openAlert}
        onClose={onClose}
        message={operationMessage}
        title={operationTitle}
      />
    </div>
  );
};

export default RecoveryPasswordForm;
