import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@mui/material';
import { PasswordInput } from '@fingo/lib/components/inputs';

const SignInPasswordCollapsableComponent = (
  { currentStep, selectedTab, formInput, errorForm, formChange },
) => (
  <Collapse
    in={currentStep === 1 && selectedTab === 'signIn'}
    sx={{
      width: '100%',
      marginTop: '5px',
    }}
  >
    <PasswordInput
      name="password"
      label="Contraseña"
      variant="outlined"
      fullWidth
      value={formInput.password}
      onChange={(event) => formChange('password', event)}
      error={errorForm?.password}
      helperText={errorForm.password}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        style: {
          borderRadius: '30px',
        },
      }}
    />
  </Collapse>
);

SignInPasswordCollapsableComponent.propTypes = {
  currentStep: PropTypes.number.isRequired,
  formInput: PropTypes.shape({ password: PropTypes.string }).isRequired,
  errorForm: PropTypes.shape({ password: PropTypes.string }).isRequired,
  selectedTab: PropTypes.string.isRequired,
  formChange: PropTypes.func.isRequired,
};

export default SignInPasswordCollapsableComponent;
