import { getFilterByNameFromHistory } from '@fingo/lib/helpers';
import { useIsLogged, useSnackBars } from '@fingo/lib/hooks';
import { Button, Link, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { REQUEST_PRE_OFFERS_EVALUATION_BY_HASH } from '@fingo/lib/graphql';

const PreOfferEvaluation = () => {
  const history = useHistory();
  const { preofferHash } = useParams();
  const isLogged = useIsLogged();
  const folio = useMemo(() => getFilterByNameFromHistory(history, 'globalFilter'), [history]);
  const { addAlert } = useSnackBars();
  const [requestPreofferEvaluation] = useMutation(
    REQUEST_PRE_OFFERS_EVALUATION_BY_HASH,
    { variables: { preofferHash },
      onError: () => {
        addAlert({
          id: 'preoffer-hash',
          message: 'Oferta ya no disponible',
        });
        history.replace({ pathname: '/app/home' });
      },
      onCompleted: () => {
        addAlert({
          id: 'preoffer-hash',
          message: 'Documento envíado a evaluar con éxito!',
        });
        history.replace({ pathname: '/app/home' });
      },
    },
  );
  useEffect(() => {
    if (isLogged) {
      history.replace({
        pathname: '/app/sales',
        search: history.location.search,
      });
    }
  }, [isLogged, history]);
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        spacing={3}
        width={{ xs: '100%', md: '50%' }}
        height={{ xs: '100%', md: '50%' }}
        alignItems="center"
        justifyContent="center"
        sx={{ background: 'white', borderRadius: 2 }}
      >
        <Typography variant="h4" width={{ xs: '100%', md: '85%' }} textAlign="center" alignSelf="center">
          Haz click para enviar a evaluar la factura {folio},
          en nuestra plataforma <b>sin ningún compromiso</b>!
        </Typography>
        <Button variant="contained" size="small" onClick={requestPreofferEvaluation}>
          Enviar a evaluar
        </Button>
        <span>
          <Typography component="span">Si quieres ver otros documentos, anda al </Typography>
          <Link whiteSpace="pre" component={RouterLink}> inicio de sesión</Link>
        </span>
      </Stack>
    </Stack>
  );
};
export default PreOfferEvaluation;
