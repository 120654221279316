import React from 'react';
import { WaveFullHeightBox } from '@fingo/lib/components/boxes';
import LoginLanding from './LoginLanding';

const LoginLandingWithWave = () => (
  <WaveFullHeightBox>
    <LoginLanding
      paperSx={{
        width: { xs: '90%', sm: 500 },
        mx: 'auto',
        py: 4,
        position: 'absolute',
        borderRadius: '20px',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  </WaveFullHeightBox>
);

export default LoginLandingWithWave;
